import { PublicClientApplication, LogLevel } from "@azure/msal-browser"; // Add this import

export const msalConfig = {
  auth: {
    //PROD credentials
    clientId: '380146ba-8a46-4b3f-b2e8-8a448d199f94',
    authority: 'https://login.microsoftonline.com/52925b26-9cba-4c12-87e6-23007e20f9fa',

    // DEV Credentials
    // clientId: "cc55937c-d978-404d-ad2d-37e994602200",
    // authority:
    //   "https://login.microsoftonline.com/c2486751-438c-4293-8e06-063e4e40a672",

    // redirectUri: "https://draytalk-ui-dev.azurewebsites.net/",
    // postLogoutRedirectUri: "https://draytalk-ui-dev.azurewebsites.net/",
    // redirectUri: "http://localhost:3000",
    // postLogoutRedirectUri: "http://localhost:3000",
    // redirectUri: "https://draytalk.com/",
    // postLogoutRedirectUri: "https://draytalk-ui-dev.azurewebsites.net/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error: // Use LogLevel from the imported msal module
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            break;
        }
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Verbose, // Use LogLevel from the imported msal module
    },
  },
};
